@font-face {
    font-family: 'Infinita';
    src: url('../../../../fonts/infinita/Infinita.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Infinita Bold';
    src: url('../../../../fonts/infinita/infinita_bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}