.cookie-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    z-index: 9999; /* Ensure the overlay appears above other content */
}

.cookie-overlay-container {
    border: 2px solid blue;
}

#custom-cookie {
    border: 2px solid green;
}

.cookie-title {
    font-family: "Playfair" !important;
}

.cookie-container .accept-button {
    color: #4e503b !important;
    font-size: 1vw !important;
    background-color: #ffffff !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    border: none !important;
    cursor: pointer !important;
}

.cookie-container .decline-button {
    color: #ffffff !important;
    font-size: 1vw !important;
    background-color: transparent !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    border: 1px solid #ffffff !important;
    cursor: pointer !important;
    margin-left: 10px !important;
}

@media only screen and (max-width: 1200px) {
    .cookie-container .accept-button {
        font-size: 1.8vw !important;

    }
    .cookie-container .decline-button {
        font-size: 1.8vw !important;

    }

}

@media only screen and (max-width: 1024px) {
    .cookie-container .accept-button {
        font-size: 2.2vw !important;
    }

    .cookie-container .decline-button {
        font-size: 2.2vw !important;
    }
}

@media only screen and (max-width: 768px) {
    .cookie-title {
        font-size: 5vw !important;
    }

    .cookie-text {
        font-size: 2vw !important;
    }
}

@media only screen and (max-width: 600px) {
    .cookie-title {
        font-size: 9vw !important;
    }

    .cookie-text {
        font-size: 3.5vw !important;
        margin-top: 1.5vw !important;
        width: 80%;
    }

    .cookie-container .accept-button {
        font-size: 4vw !important;
    }

    .cookie-container .decline-button {
        font-size: 4vw !important;
    }
}