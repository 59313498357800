.carousel-container {
    margin-top: 3.5vw;
    z-index: 0;
}

.carousel-inner-container {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

#carousel-title {
    letter-spacing: 0.5vw;
    margin-bottom: 2vw;
    margin-left: 1vw;
    font-size: 3vw;
    font-family: 'Raleway';
}

#carousel-title span {
    font-family: 'Roboto Condensed';
}

.carousel-container img {
    border-radius: var(--main-border-radius);
    /*box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), 
                0 2px 4px rgba(0, 0, 0, 0.2), 
                0 8px 20px rgba(0, 0, 0, 0.2);*/
    width: 100%;
    height: 47vw;
    display: flex;
    /*object-fit: cover;*/   
}

.carousel-container .title-holder {
    width: 100%;
    margin: 10vw 0 10vw 0;
}

.carousel-container .legacy-text {
    font-family: 'Playfair';
    text-align: center;
}

#divider-darktheme {
    background-color: white;
    width: 95%;
    margin:auto;
}

#divider-lighttheme {
    background-color: grey;
    width: 95%;
    margin:auto;
}

#swiper-container {
    /* border: 1px solid red; */
    padding-bottom: 2vw;
    width: 99%;
}

@media only screen and (max-width: 1245px) {
    .carousel-container img {
        width: 100%;
        height: 50vw;
    }

    .title-holder {
        display: flex;
        justify-content: center;
    }

    .carousel-container .legacy-text {
        font-size: 3vw;
        width: 95%;
    }

} 

@media only screen and (max-width: 1024px) {
    .carousel-container {
        margin-top: 15vw;
        transition: filter 0.4s;
    }

    #carousel-title {
        font-size: 4vw;
        margin-bottom: 3vw;
    }

    .carouse-container-blurred {
        filter: blur(5px);
        transition: filter 0.7s;
    }

    .carousel-container img {
        height: 67vw;
        width: 100%;
    }

    .carousel-container .legacy-text {
        font-family: 'Playfair';
        text-align: center;
        font-size: 4.2vw;
        width: 95%;
    }
}

@media only screen and (max-width: 768px) {
    .carousel-container {
        margin-top: 15vw;
        margin-bottom: 15vw;
    }

    .carousel-title {
        font-size: 5vw;
    }

    .carousel-container img {
        height: 75vw;
        /*border: 1px solid blue;*/
        width: 100%;
    }

    .title-holder .legacy-text {
        margin: 0;
        font-size: 5vw;
    }

    .carousel-container .title-holder {
        width: 100%;
        margin-top: 20vw;
        margin-bottom: 20vw;
        /* border: 1px solid yellow; */
        height: auto;
    }
}

@media only screen and (max-width: 435px) {
    .carousel-container {
        margin-top: 22vw;
    }

    #carousel-title {
        font-size: 7vw;
        margin-bottom: 5vw;
        margin-left: 4vw;
    }

    #swiper-container {
        width: 95%;
    }

    .carousel-container img {
        height: 130vw;
    }

    .title-holder .legacy-text {
        font-size: 6vw;
        margin: 0;
    }

    .carousel-container .title-holder {
        width: 100%;
        margin-top: 20vw;
        margin-bottom: 15vw;
        /* border: 1px solid blue; */
        padding: 0;
        height: auto;
    }
}


