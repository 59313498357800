@font-face {
    font-family: 'Playfair';
    src: url('../../../../fonts/playfair/static/PlayfairDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Playfair-italic';
    src: url('../../../../fonts/playfair/static/PlayfairDisplay-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;

}