.footer-container  {
    width: 100%;
    /*border: 1px solid green;*/
    height: 12vw;
    margin-top: 7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000000;
}

.footer-container .ref-logo-container {
    width: 85%;
    /* border: 1px solid yellow; */
    margin-top: 0vw;
    margin-bottom: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-container .footer-inner-container {
    margin-top: 0vw;
    width: 85%;
    height: 7vw;
    /* border: 2px solid yellow; */
    display: flex;
    margin-bottom: 0.4vw;
}

.footer-inner-container .copyright-container,
.footer-inner-container .reference-container {
    width: 50%;
    height: 40%;
    margin-top: 1.5vw;
}

.footer-inner-container .copyright-container {
    display: flex;
    justify-content:flex-start;
    /*border: 1px solid red;*/
    height: 5vw;
}

.copyright-container .copyright-inner-container {
    /*border: 2px solid yellow;*/
    width: 50%;
    height: 100%;
}

.footer-inner-container .reference-container {
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid green; */
    height: auto;
    
}

.ref-logo-container .ref-card {
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* border: 1px solid red; */
    margin-left: 1vw;
    /* background-color: white; */
    border-radius: 50%;
    /* border: 1px solid red; */
}

.ref-logo-container .github {
    /* border: 1px solid blue; */
    width: 2vw;
    height: 2vw;
}

.ref-card .ref-icon {
    width: 120%;
    height: 106%;
}

.ref-card:hover {
    cursor: pointer;
    opacity: 0.6;
}

.ref-card .ref-anchor {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.turul-anchor {
    /* border: 3px solid green; */
    height: auto;
    width: auto;
}

.turul-anchor .turul-logo {
    /* border: 3px solid yellow; */
    margin: 0;
    width: 12vw;
    height: 3vw;
    
}

.turul-anchor .turul-logo:hover {
    opacity: 0.6;
    
}

@media only screen and (max-width: 1250px) {
    .ref-logo-container .ref-card {
        width: 3vw;
        height: 3vw;
    }
}

@media only screen and (max-width: 1024px) {

    .footer-container {
        height: 16vw;
        transition: filter 0.4s;
        
    }

    .footer-container-blurred {
        filter: blur(5px);
        transition: filter 0.7s;
    }

    .footer-container .footer-inner-container {
        width: 100%;
        margin-top: 3vw;
        height: 10vw;
    }


    .footer-inner-container .copyright-container,
    .footer-inner-container .reference-container {
        height: 80%;
        /*xborder: 1px solid red;*/
        margin-top: 2vw;
    }

    .copyright-container .copyright-inner-container {
        width: 100%;
    }

    .reference-container .reference-inner-container {
        width: 80%;
    }

    .ref-card .ref-icon {
        width: 100%;
        height: 100%;
    }

    .ref-logo-container .ref-card {
        width: 5vw;
        height: 5vw;
        margin-right: 1vw;
    }

    .turul-anchor .turul-logo {
        width: 28vw;
        height: 7vw;
    }

    .reference-inner-container .first,
    .reference-inner-container .second {
        margin-right: 0vw;
        
    }
}

@media only screen and (max-width: 768px) {
    .footer-container {
        height: 20vw;
       
    }

    .footer-container .footer-inner-container {
        height: 14vw;
        width: 95%;
    }

    .footer-inner-container .copyright-container,
    .footer-inner-container .reference-container {
        margin-top: 3vw;
    }

    .reference-inner-container .first,
    .reference-inner-container .second {
        margin-right: 0.5vw;
        
    }

    .ref-logo-container  .ref-card {
        width: 5vw;
        height: 5vw;
    }

    .ref-card .ref-icon {
        width: 100%;
        height: 100%;
        
    }

    .turul-anchor .turul-logo {
        width: 32vw;
        height: 8vw;
    }

    .radio-label {
        font-size: 3vw;
    }
}

@media only screen and (max-width: 435px) {
    .footer-container {
        /*border: 2px solid blue;*/
        height: auto;
    }

    .footer-container .footer-inner-container {
        height: auto;
    }

    .footer-container .ref-logo-container {
        /* border: 1px solid red; */
        margin-top: 7vw;
        height: auto;
        width: 100%;
    
        
    }

    .ref-card .ref-anchor {
        /* border: 1px solid blue; */
        height: 10vw;
    }

    .ref-logo-container .ref-card {
        /* border: 3px solid red; */
        width: 10vw;
        height: 13vw;
    }

    .turul-anchor .turul-logo {
        width: 40vw;
        height: 12vw;
        margin-top: 2vw;
    }

}