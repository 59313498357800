.seasoncard-container {
    width: 100%;
    /* border: 1px solid blue; */
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7vw;
    margin-bottom: 7vw;
}

.seasoncard-inner-container {
    width: 95%;
    height: auto;
    display: flex;
    /* border: 2px solid green; */

}

.seasonone,
.seasontwo,
.seasonthree,
.seasonfour {
    width: 25%;
    height: 34vw;
    opacity: 0.6;
}

.seasonone {
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
}

.seasontwo {
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    opacity: 0.5;
}

.seasonthree {
    background-size: 100%;
    background-repeat: no-repeat;
}

.seasonfour {
    background-size: 100%;
    background-repeat: no-repeat;
}

.textbox {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    
}

#postertext {
    color: white;
    font-family: 'Oswald';
    letter-spacing: 0.2vw;
    font-size: 9vw;
    opacity: 1;
}

#preview-button {
    width: auto;
    height: auto;
    background-color: black;
    opacity: 0.8;
    
}

@media only screen and (max-width: 1245px) {
    #postertext {
        font-size: 15vw;
    }

    #preview-button {
        font-size: 1vw;
        margin-top: 0.5vw;
    }

    #postertext {
        font-size: 13vw;
    }

} 

@media only screen and (max-width: 1024px) {
    #preview-button {
        font-size: 2vw;
        margin-top: 0.5vw;
    }

    .seasoncard-img-container {
        height: 70vw;
        background-size: 100%;
        width: 33.33%;
    }
    
    .seasoncard-container {
        margin-top: 10vw;
        margin-bottom: 10vw;
    }

    .seasonone {
        display: none;
    }

    .seasoncard-inner-container {
        width: 100%;
    }

    #postertext {
        font-size: 14vw;
        z-index: 1;
        
    }

    #preview-button {
        font-size: 1.5vw;
        margin-top: 0.5vw;
        
    }

    .textbox {
        /* border: 2px solid red;  */
        /* bottom: 8%;  */
        opacity: 0.8;
        z-index: 10;
        margin-bottom: 15vw;
    }  
}

@media only screen and (max-width: 768px) {
    #preview-button {
        font-size: 2.2vw;
        margin-top: 2vw;
    }

    .seasoncard-img-container {
        width: 50%;
    }

    .seasonfour {
        display: none;
    }

    .textbox {
        /* bottom: 30%; */
        top: 35%;
        /* border: 1px solid yellow; */
    }
}

@media only screen and (max-width: 435px) {
    #preview-button {
        font-size: 3vw;
        margin-top: 2vw;
    }

    .textbox {
        bottom: 5%;
        opacity: 1;
    }

    #postertext {
        opacity: 1;
    }


}
