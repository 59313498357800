
.header-container {
    width: 100%;
    height: 100%;
    transition: height 0.5s;
    display: flex;
    height: 5vw;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

.header-light {
    border-bottom: 1px solid rgb(158, 158, 158);
}

.header-night {
    background-color: black;
}

header .logo-container,
.navbar-container {
    width: 50%;
    
}

.logo-holder {
    cursor: pointer;
   
}

.return-home-button {
    text-decoration: none;
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0;
}

.navbar-container {
    display: flex;
    justify-content: flex-end;
}

.navbar-section {
    background-color: antiquewhite;
    width: 100%;
    display: flex;
    align-items: center;
}

.navbar-container .active {
    font-weight: 900;
    text-decoration: none;
}

.navbar-container .inactive {
    text-decoration: none;
}

.navbar-cont-dark .active  {
    color: white;
   
}

.navbar-cont-dark .inactive {
    color:white;
}


.navbar-container .nav {
    padding: 0.8vw;
    overflow:hidden;
    font-family: 'Quicksand';
    letter-spacing: 0.11vw;
}

.navbar-cont-dark li {
    color: white;
}

/*Logo adjustment section*/
.logo-holder .brand-logo {
    font-family: 'Infinita';
    font-size: 2vw;
    letter-spacing: 3px;
    font-weight: "bold";
    transition: font-size 0.5s;
    /*width: 100%;
    height: 100%;*/
    text-align: center;
    transform: translateY(1vw);
}

.logo-holder .brand-logo-ext {
    font-family: 'Infinita';
    transform: translateY(-3vw);
    letter-spacing: 2.5px;
    font-size: 0.85vw;
    font-weight: 800;
}

/***/

.navbar-cont-dark .navbar-section {
    background-color: black;
}

.navbar-cont-light .nav {
    color: black;
}

header .logo-container {
    display: flex;
    align-items: center;
}

header .logo-container-light {
    background-color: antiquewhite;

}

header .logo-container-light .logo-holder {
    background-color: antiquewhite;
}

header .logo-container-light .brand-logo,
header .logo-container-light .brand-logo-ext {
    color: black;
    box-shadow: 2px 2px 5px 0 rgba(255, 255, 255, 0.2);
}

header .logo-container-dark .brand-logo,
header .logo-container-dark .brand-logo-ext {
    color: white;
    
}

header .logo-container-dark .logo-holder {
    background-color: black;
    box-shadow: 2px 2px 5px 0 rgba(255, 255, 255, 0.2);
    transition: box-shadow 1s;
}

header .logo-container-light .logo-holder {
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 1s;
}

header .logo-container-dark {
    background-color: black;
}

header .logo-container .logo-holder {
    width: 15%;
    height: 60%;
    margin-left: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-desktop .theme {
    cursor: pointer;
}

.navbar-desktop {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content:flex-end;
    align-items: center;
    list-style-type: none;
}

.navbar-desktop .theme_holder {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;   
}

.navbar-desktop #changetheme-button {
    /* border: 1px solid red; */
    margin: 0;
}

.navbar-desktop li {
    margin: 1vw;
}


.navbar-mobile {
    display: none;
}

.hamburger-icon {
    display: none;
    
}

.close-icon {
    display: none;
}

/*MUI styls
*/
#theme-switch .Mui-checked {
    background-color: red;
}

/*///*/
@media only screen and (max-width: 1450px) {
    .navbar-desktop li {
        font-size: 1vw;
    }

    /*Logo adjustment section*/
    .logo-holder .brand-logo {
        font-family: 'Infinita Bold';
        font-size: 2vw;
        transition: font-size 0.5s;
        /*width: 100%;
        height: 100%;*/
        text-align: center;
        transform: translateY(1.1vw);
    }

    .logo-holder .brand-logo-ext {
        font-family: 'Infinita';
        transform: translateY(-3.1vw);
        letter-spacing: 2px;
        font-size: 0.9vw;
    }

    /***/
}

@media only screen and (max-width: 1024px) {
    .logo-holder .brand-logo {
        font-family: 'Infinita';
    }

    .navbar-desktop {
        display: none;
    }

    .header-at-null {
        /* border: 3px solid blue; */
        height: 9.5vw;
        transition: height 0.5s;
    }

    .header-container {
        z-index: 100;
    }

    /*Logo adjustment area*/
    .header-at-null .logo-holder .brand-logo-ext {
        font-size: 1.2vw;
        transform: translateY(-4.5vw);
        letter-spacing: 0.25vw;
        transition: all 0.5s;
        font-weight: 900;

    }

    .header-at-null .logo-holder .brand-logo {
        font-size: 3.1vw;
        transition: font-size 0.5s;
        letter-spacing: 0.35vw;
        transform: translateY(1.4vw);
        font-weight: 900;
        
    }
    
    .header-not-at-null .logo-holder .brand-logo {
        letter-spacing: 0.28vw;
        font-size: 2.4vw;
        transform: translateY(1.3vw);
        font-weight: 900;

    }

    .header-not-at-null .logo-holder .brand-logo-ext {
        font-size: 0.9vw;
        letter-spacing: 0.25vw;
        transform: translateY(-3.3vw);
        transition: all 0.5s;
        font-weight: 900;

    }

    /*****/
    /*Adjust transition for width when header enter null offset*/
    .header-at-null .logo-container .logo-holder {
        width: 23%;
        transition: width 0.5s;
        
    }

    /*Adjust transition for width when header leaves null offset*/
    .header-not-at-null .logo-container .logo-holder {
        transition: width 0.5s;
        width: 20%;
    }


    .header-not-at-null {
        height: 7vw;
    }

    .hamburger-icon {
        display: block;
        width: 4vw;
        
    }

    .hamburger-container {
        position: absolute;
        right: 1vw;
    }

    .header-at-null .navbar-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        position: absolute;
        top: 7.5vw;
        transition: top 0.5s;
        right: 0;
    }

    .close-icon {
        display: block;
        width: 4vw;
    }

    .navbar-mobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        position: absolute;
        align-items: flex-start;
        transition: top 0.5s;
        right: 0;
        top: 5vw;
        /* border: 1px solid black; */
        padding-top: 1vw;
    }

    .navbar-container .active {
        text-decoration: underline;
        font-weight: 200;
    }

    .header-at-null .navbar-mobile,
    .navbar-mobile {
        opacity: 1;
    }

    /*Adjust opacity of header element while on light theme*/
    .nav-light {
        background-color: rgba(250, 235, 215, 0.85);
    }

    /*Adjust opacity of header element while on dark theme*/
    .nav-night {
        background-color: rgba(0, 0, 0, 0.85);
    }
   

    .navbar-mobile li {
        /*border: 1px solid red;*/
        font-size: 1.6vw;
        display: flex;
        margin: 1vw;
        justify-content: flex-start;
        list-style-type: none;
    }

    .header-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .header-container .return-home-button {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-mobile {
        top: 4.75vw;
        border-radius: 0 0 20px 20px;
    }

    .header-at-null .navbar-mobile {
        top: 7.75vw;
        
    }

    .navbar-mobile {
        padding-top: 1.5vw;
    }

    .header-at-null .logo-holder h4  {
        font-size: 3vw;
    }

    .header-at-null .logo-holder {
        width: 12vw;
        
    }

    .header-not-at-null .logo-holder h4  {
        font-size: 2.3vw;
    }

    .navbar-mobile li {
        font-size: 2.2vw;
        margin: 1.6vw;
    }

    .header-at-null {
        /*border: 3px solid blue;*/
        height: 11vw;
        transition: height 0.5s;
    }

    .header-not-at-null {
        height: 8vw;
    }
}

@media only screen and (max-width: 435px) {
    .logo-holder .brand-logo {
        /* font-family: "Infinita Bold"; */
        font-weight: 100;
    }

    .header-container {
        height: 11vw;
    }

    .header-at-null {
        height: 14.5vw;
    }

    .logo-holder .brand-logo-ext {
        /* font-family: "Infinita Bold"; */
    }

    /*Logo adjustment area*/
    .header-at-null .logo-holder .brand-logo-ext {
        font-size: 2vw;
        transform: translateY(-6.7vw);
        letter-spacing: 0.15vw;
        transition: all 0.5s;
    }

    .header-at-null .logo-holder .brand-logo {
        font-size: 4.8vw;
        letter-spacing: 0.35vw;
        transform: translateY(2.6vw);
        transition: font-size 0.5s;
        
    }
    
    .header-not-at-null .logo-holder .brand-logo {
        letter-spacing: 0.2vw;
        font-size: 3.7vw;
        transform: translateY(1.9vw);
    }

    .header-not-at-null .logo-holder .brand-logo-ext {
        font-size: 1.3vw;
        letter-spacing: 0.1vw;
        transform: translateY(-4.8vw);
        transition: all 0.5s;
    }

    /*****/
    

    /*Determine the offset of the navbar on mobiles, while not scrolled all the way up*/
    .navbar-mobile {
        top: 6.75vw;
    }

    /*Determine the offset of the navbar on mobiles, while scrolled all the way up*/
    .header-at-null .navbar-mobile {
        top: 10.25vw;
    }

    .navbar-mobile .nav {
        font-size: 3.5vw;
        margin: 1vw;
        margin-left: 0;
        /* border: 2px solid red; */
    }

    .navbar-mobile .theme-switch-mobile {
        /* border: 2px solid red; */
        margin-top: 4vw;
        margin-left: 2vw;
    }

    .navbar-mobile #theme-switch-mobile-button {
        size: medium;
    }

    .hamburger-icon,
    .close-icon {
        width: 15vw;
    }

    header .logo-container .logo-holder  {
        /*border: 2px solid red;*/
        height: 75%;
        width: 14vw;
    }

    .logo-container .return-home-button {
        /*border: 1px solid red;*/
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
    }

    /*Scroll logo font-size, when not scrolled all the way up*/
    .header-container .logo-holder h4 {
        font-size: 4vw;
        letter-spacing: 0.2vw;
        font-weight: 900;
        
    }

    .header-at-null .logo-container .logo-holder {
        width: 35%;
    }

    .header-not-at-null .logo-container .logo-holder {
        width: 27%;
    }
}

