@font-face {
    font-family: 'Raleway-Light';
    src: url('../../../../fonts/raleway/static/Raleway-Light.ttf') format('truetype');
    font-weight: light;
    font-style: normal;

}

@font-face {
    font-family: 'Raleway';
    src: url('../../../../fonts/raleway/static/Raleway-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Raleway-Extralight';
    src: url('../../../../fonts/raleway/static/Raleway-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}